import {Component, Input, OnChanges} from "@angular/core";
import {MatIconModule} from "@angular/material/icon";
import {NgIf} from "@angular/common";

const isProducer = (siteType: { producer?: boolean }[]) =>
  siteType && siteType.some(({producer}) => producer);

const isFactory = (siteType: { factory?: boolean, producer?: boolean }[]) =>
  siteType && siteType.some(({factory}) => factory) && siteType.every(({producer}) => !producer);


@Component({
  selector: 'app-site-icon',
  standalone: true,
  template: `
    <mat-icon *ngIf="!_factory && _home && !_producer">person</mat-icon>
    <mat-icon *ngIf="!_factory && !_home && !_producer">pallet</mat-icon>
    <mat-icon *ngIf="_factory && !_home && !_producer">factory</mat-icon>
    <mat-icon *ngIf="!_factory && !_home && _producer">forest</mat-icon>
  `,
  imports: [MatIconModule, NgIf],
})
export class SiteIconComponent implements OnChanges {
  @Input() siteType!: ({ home?: boolean, producer?: boolean; factory?: boolean })[];

  _home: boolean = false;
  _factory: boolean = false;
  _producer: boolean = false;

  ngOnChanges() {
    this._factory = isFactory(this.siteType);
    this._producer = isProducer(this.siteType);
    this._home = this.siteType && this.siteType.some(({home}) => home);
  }
}
